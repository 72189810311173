<template>
  <div class="l__wrapper t__bg-light-blue pc-pt60 sp-pt40 sp-pb40">
    <form class="l__inner text-search pc-pb60 sp-pb50">
      <div class="m__form__input">
        <p class="pc-mb5">住所や駅名を入力</p>
        <input type="text" v-model="query" placeholder="（例）東京都新宿区●●● / 新宿駅" />
      </div>
      <div class="search-btn m__btn__white m__btn__search sp-mt20">
        <span>
          <input type="submit" @click.prevent="searchLocation" value="検索する" />
        </span>
      </div>
    </form>
    <div class="map-box">
      <div ref="map" class="map">MAP</div>
      <div v-if="openDialog" class="info-box">
        <section>
          <h2 class="title sp">
            <div class="text">保育園の情報</div>
          </h2>
          <div class="content">
            <section class="info">
              <picture class="image">
                <source :srcset="webpPath" type="image/webp" />
                <img :src="imagePath" :alt="facilityName" />
              </picture>
              <div class="text">
                <h3 class="name pc-mt8">{{ facilityName }}</h3>
                <p class="type sp-mt3">{{ facilityType }}</p>
                <p class="address pc-mt3 sp-mt3">{{ facilityAddress }}</p>
              </div>
            </section>
            <div v-if="facilityPath">
              <div class="m__btn__white m__btn__white--w300 m__btn__link pc-mt15 sp-mt30">
                <a :href="facilityPath">この保育園の情報を詳しく見る</a>
              </div>
            </div>
            <div v-if="routeUrl">
              <div class="m__btn__white m__btn__white--w300 m__btn__search m__btn__blank pc-mt10 sp-mt15">
                <a :href="routeUrl" target="_blank">経路を検索する</a>
              </div>
            </div>
            <a @click.prevent="closeDialog" href="" class="close-btn">
              <span class="pc">閉じる</span>
              <div class="icon sp">
                <img src="/resources/images/brand/icon/icon_modal_close.svg">
              </div>
              <p class="text sp">閉じる</p>
            </a>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      zoom: 13, // Default zoom level
      map: null,
      query: '',
      facilities: [],
      selectedFacility: null,
      openDialog: false,
      markers: new google.maps.MVCArray()
    }
  },
  computed: {
    webpPath() {
      if (!this.selectedFacility) return

      return this.selectedFacility.webp
    },
    imagePath() {
      if (!this.selectedFacility) return

      return this.selectedFacility.image
    },
    facilityName() {
      if (!this.selectedFacility) return

      return this.selectedFacility.name
    },
    facilityType() {
      if (!this.selectedFacility) return

      return this.selectedFacility.type
    },
    facilityAddress() {
      if (!this.selectedFacility) return

      return this.selectedFacility.address
    },
    facilityPath() {
      if (!this.selectedFacility) return

      return this.selectedFacility.path
    },
    routeUrl() {
      if (!this.selectedFacility) return

      const lat = this.selectedFacility.latitude
      const lng = this.selectedFacility.longitude
      const url = `https://www.google.co.jp/maps/dir/%e7%8f%be%e5%9c%a8%e5%9c%b0/${lat},${lng}`

      return url
    }
  },
  methods: {
    refresh() {
      this.facilities.forEach((f) => {
        const marker = new google.maps.Marker({
          position: new google.maps.LatLng(f.latitude, f.longitude),
          map: this.map,
          draggable: false,
          icon: '/resources/images/brand/facilities/map/pin.png'
        })
        this.markers.push(marker)

        google.maps.event.addListener(marker, 'click', () => {
          this.map.panTo(marker.getPosition())

          this.selectedFacility = f
          this.openDialog = true
        })
      })
    },
    closeDialog() {
      this.selectedFacility = null
      this.openDialog = false
    },
    searchLocation() {
      if (!this.query) return

      const geocoder = new google.maps.Geocoder()
      const q = { 'address': this.query, 'region': 'jp' }
      const map = this.map
      const zoom = this.zoom

      geocoder.geocode(q, (results, status) => {
        if (status != google.maps.GeocoderStatus.OK) return

        const latlng = results[0].geometry.location
        map.setCenter(latlng)
        map.setZoom(zoom)
      })
    }
  },
  mounted() {
    const mapCanvas = this.$refs.map
    const mapOptions = {
      center: new google.maps.LatLng(35.688526, 139.692387),
      zoom: this.zoom,
      mapTypeControlOptions: {
        mapTypeId: google.maps.MapTypeId.ROADMAP
      },
      draggable: true,
      scrollwheel: false
    }
    this.map = new google.maps.Map(mapCanvas, mapOptions)

    const json = document.querySelector('#js-facilities-json').innerHTML
    this.facilities = JSON.parse(json)

    const queryJson = document.querySelector('#js-facilities-query').innerHTML
    this.query = JSON.parse(queryJson).query

    this.refresh()

    if (this.query) this.searchLocation()
  }
}
</script>
