import Chart from 'chart.js/auto'
export const chart = () => {
  const chartExec = () => {
    const chartArr = document.querySelectorAll('.js__chart')
    if (chartArr != null) {
      chartArr.forEach((chartElem) => {
        const scrollElemPos = chartElem.getBoundingClientRect()
        const scrollEventPoint = scrollElemPos.top - (window.innerHeight / 3) - 303
        if (scrollEventPoint < 0 && chartElem.classList.contains('draw') == false) {
          chartElem.classList.add('draw')
          chartElem.parentElement.classList.add('fade-in')
          setTimeout(() => {
            const ctx = chartElem.getContext('2d')
            const ratio = chartElem.dataset.ratio.split(',')
            const blue_gradient = ctx.createLinearGradient(0, 0, 0, 240);
            blue_gradient.addColorStop(0, '#0083c0');
            blue_gradient.addColorStop(1, '#5dc1d0');
            new Chart(ctx, {
              type: 'doughnut',
              data: {
                datasets: [{
                  data: ratio,
                  borderWidth: 0,
                  backgroundColor: [blue_gradient, '#e5e1e1']
                }]
              },
              options: {
                responsive: false,
                cutoutPercentage: 80,
                gridLines: {
                  drawTicks: false,
                  display: false,
                  drawBorder: false
                },
                plugins: {
                  tooltip: {
                    enabled: false,
                  },
                }
              }
            })
            const duration = 600
            const countElem = chartElem.nextElementSibling.querySelector('.js__countup')
            const from = parseInt(countElem.dataset.from, 10)
            const to = parseInt(countElem.dataset.to, 10)
            const startTime = Date.now()
            const timer = setInterval(() => {
              const elapsedTime = Date.now() - startTime
              const progress = elapsedTime / duration
              if (progress < 1) {
                countElem.textContent = Math.floor(from + progress * (to - from))
              } else {
                countElem.textContent = to
                clearInterval(timer)
              }
            }, 16)
          }, 300)
        }
      })
    }
  }
  document.addEventListener('DOMContentLoaded', () => {
    chartExec()
  })
  window.addEventListener('scroll', () => {
    chartExec()
  })
}
