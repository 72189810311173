export const modal = () => {
  const modalTriggers = document.querySelectorAll('.js__modal-trigger')
  const modaltargets = document.querySelectorAll('.js__modal-target')
  const modalInners = document.querySelectorAll('.m__modal__wrap .m__modal__inner')
  const modalCloseBtns = document.querySelectorAll('.js__modal-close-btn')
  modalTriggers.forEach((modalTrigger) => {
    const dataModal = modalTrigger.dataset.modal
    modalTrigger.addEventListener('click', () => {
      const targetModal = document.getElementById(dataModal)
      if (targetModal.classList.contains('open') == false) {
        targetModal.classList.add('open')
      } else {
        targetModal.classList.remove('open')
      }
    })
    modalCloseBtns.forEach((modalCloseBtn) => {
      modalCloseBtn.addEventListener('click', (event) => {
        event.stopPropagation()
        document.getElementById(dataModal).classList.remove('open')
      })
    })
    modaltargets.forEach((modaltarget) => {
      modaltarget.addEventListener('click', () => {
        document.getElementById(dataModal).classList.remove('open')
      })
    })
    modalInners.forEach((modalInner) => {
      modalInner.addEventListener('click', (event) => {
        event.stopPropagation()
      })
    })
  })
}
