export const floatBanner = () => {
  const floatBannerContent = document.querySelector('.js__float-banner')
  const footer = document.querySelector('.m__footer')
  const pagetopBtn = document.querySelector('.js__scroll-fixed')
  if (floatBannerContent != null) {
    if (window.innerWidth > 736) {
      const floatHeight = floatBannerContent.clientHeight
      footer.style.paddingBottom = floatHeight + 'px'
      pagetopBtn.style.marginBottom = 70 + 'px'
    } else {
      const floatHeight = floatBannerContent.clientHeight
      footer.style.paddingBottom = floatHeight + 40 + 'px'
      pagetopBtn.style.marginBottom = 50 + 'px'
    }
  }
}
