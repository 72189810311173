export const smoothScroll = () => {
  const smoothScroll = document.querySelectorAll('a[href^="#"]')
  const urlHash = location.hash
  const header = document.querySelector('.m__header')
  let headerHeight = 45
  if (window.innerWidth < 737) {
    headerHeight = 30
  }
  if (header.classList.contains('js__header-fixed') == true) {
    headerHeight = document.querySelector('.m__header').clientHeight
    if (window.innerWidth >= 737) {
      headerHeight = document.querySelector('.m__header').clientHeight - 75
    }
  }

  smoothScroll.forEach((scrollElem) => {
    scrollElem.addEventListener('click', (e) => {
      e.preventDefault()
      let href = scrollElem.getAttribute('href')
      let target = document.getElementById(href.replace('#', ''))
      const rect = target.getBoundingClientRect().top - headerHeight
      const offset = window.pageYOffset
      const position = rect + offset
      window.scrollTo({
        top: position,
        behavior: 'smooth',
      });
    });
  })
  //別ページからIDで遷移してくる場合
  document.addEventListener('DOMContentLoaded', () => {
    if (urlHash) {
      setTimeout(function (){
        window.scrollTo({
          top: 0
        }, 0)
      })
      setTimeout(function () {
        const urlTarget = document.getElementById(urlHash.replace('#', ''))
        const urlPosition = window.pageYOffset + urlTarget.getBoundingClientRect().top - headerHeight
        window.scroll({
          top: urlPosition,
          behavior: 'smooth'
        })
      }, 200)
    }
  })
}
