import Swiper from 'swiper/bundle'
export const carousel = () => {
  document.addEventListener('DOMContentLoaded', () => {
    const basicCarousels = document.querySelectorAll('.m__carousel__basic')
    basicCarousels.forEach((basicCarousel) => {
      const swiperListCount = basicCarousel.querySelectorAll('.swiper-slide').length
      if (swiperListCount > 1) {
        new Swiper(basicCarousel, {
          loop: true,
          slidesPerView: 'auto',
          centeredSlides: true,
          autoplay: {
            delay: 4000,
          },
          speed: 500,
          pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true,
          },
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
        })
      }
    })

    const carouselThumbnail = new Swiper('.m__carousel__facilities-360view-thumb', {
      slidesPerView: 'auto',
      watchSlidesVisibility: true,
      speed: 500,
      watchSlidesProgress: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      }
    })
    const carouselViews = document.querySelectorAll('.m__carousel__facilities-360view')
    carouselViews.forEach((carouselView) => {
      new Swiper(carouselView, {
        loop: true,
        slidesPerView: 'auto',
        centeredSlides: true,
        speed: 500,
        thumbs: {
          swiper: carouselThumbnail
        }
      })
    })
    const blogCarousels = document.querySelectorAll('.m__carousel__facilities-blog')
    blogCarousels.forEach((blogCarousel) => {
      const blocCarouselLength = blogCarousel.querySelectorAll('.swiper-slide').length
      if (blocCarouselLength > 1) {
        const slider = new Swiper(blogCarousel, {
          loop: true,
          slidesPerView: 'auto',
          centeredSlides: true,
          useCSS3Transforms: false,
          speed: 800,
          loopedSlides: 10,
          autoplay: {
            delay: 2200,
            disableOnInteraction: false
          },
          pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true,
          },
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
          on: {
            realIndexChange: function() {
              blogCarousel.classList.add('start-swipe')
              setTimeout(() => {
                blogCarousel.classList.remove('start-swipe')
              }, 2000)
              if (this.realIndex === 0) {
                setTimeout(function() {
                  blogCarousel.classList.add('swiper-loop')
                  slider.slideToLoop(0, 0)
                }, 2000)
                setTimeout(function() {
                  blogCarousel.classList.remove('swiper-loop')
                }, 2100)
              }
            }
          }
        })
      }
    })

    const topMainCarousels = document.querySelectorAll('.m__carousel__top-carousel')
    topMainCarousels.forEach((topMainCarousel) => {
      const topCarouselLength = topMainCarousel.querySelectorAll('.swiper-slide').length
      if (topCarouselLength > 1) {
        const carouselElem = new Swiper(topMainCarousel, {
          loop: true,
          slidesPerView: 'auto',
          centeredSlides: true,
          autoplay: {
            delay: 5000,
            disableOnInteraction: false
          },
          speed: 500,
          pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true,
          },
        })
        carouselElem.autoplay.stop()
        const autoplayCheck = () => {
          const scrollElemPos = topMainCarousel.getBoundingClientRect()
          const scrollEventPos = scrollElemPos.top - (window.innerHeight / 2)
          if (scrollEventPos < 0) {
            carouselElem.autoplay.start()
          }
        }
        autoplayCheck()
        window.addEventListener('scroll', () => {
          autoplayCheck()
        })
      }
    })
    const facilitiesCarousel = document.querySelector('.m__carousel__facilities-list')
    const facilitiesListCount = facilitiesCarousel && facilitiesCarousel.querySelectorAll('.swiper-slide').length
    if (facilitiesListCount > 1) {
      new Swiper(facilitiesCarousel, {
        loop: true,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false
        },
        slidesPerView: 'auto',
        centeredSlides: true,
        speed: 500,
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true,
        },
      })
      const facilityNameArr = facilitiesCarousel.querySelectorAll('.swiper-slide .name')
      let prevHeight = 0
      facilityNameArr.forEach((facilityName) => {
        const facilityNameHeight = facilityName.clientHeight
        if (prevHeight < facilityNameHeight) {
          prevHeight = facilityNameHeight
        }
      })
      facilityNameArr.forEach((facilityName) => {
        facilityName.style.height = prevHeight + 'px'
      })
    }
    const topBottomBanner = document.querySelector('.m__carousel__bottom-banner')
    const topBottomBannerCount = topBottomBanner && topBottomBanner.querySelectorAll('.swiper-slide').length
    let bottomCarouselStartCount = 3
    if (window.innerWidth < 737) {
      bottomCarouselStartCount = 1
    }
    if (topBottomBannerCount > bottomCarouselStartCount) {
      new Swiper(topBottomBanner, {
        loop: true,
        slidesPerView: 'auto',
        speed: 500,
        loopAdditionalSlides: 4,
        autoplay: {
          delay: 4000,
          disableOnInteraction: false,
        },
        breakpoints: {
          769: {
            slidesPerView: 3,
            slidesPerGroup: 1,
            spaceBetween: 25,
          },
        },
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      })
    }
    const balloons = document.querySelectorAll('.m__top__satisfaction .balloon')
    let balloonSpeed = 6000
    if (window.innerWidth < 737) {
      balloonSpeed = 3000
    }
    balloons.forEach((balloon, index) => {
      const i = index + 1
      const targetBalloons = document.querySelectorAll('.m__top__satisfaction .balloon-' + i)
      targetBalloons.forEach((targetBalloon) => {
        setTimeout(() => {
          new Swiper(targetBalloon, {
            loop: true,
            effect: 'fade',
            speed: 800,
            autoplay: {
              delay: balloonSpeed,
              disableOnInteraction: false
            },
            on: {
              realIndexChange: function() {
                targetBalloon.classList.add('start-fade')
                setTimeout(() => {
                  targetBalloon.classList.remove('start-fade')
                }, 400)
              }
            }
          })
        }, (i * 400))

      })
    })
    const differentBlogCarousels = document.querySelectorAll('.m__carousel__different-ages-blog')
    differentBlogCarousels.forEach((differentBlogCarousel) => {
      const carouselLength = differentBlogCarousel.querySelectorAll('.swiper-slide').length
      let carouselStartCount = 3
      if (window.innerWidth < 737) {
        carouselStartCount = 1
      }
      if (carouselLength > carouselStartCount) {
        new Swiper(differentBlogCarousel, {
          slidesPerView: 1.4,
          centeredSlides: false,
          slidesPerGroupSkip: 1,
          grabCursor: true,
          spaceBetween: 20,
          breakpoints: {
            769: {
              slidesPerView: 3,
              slidesPerGroup: 1,
              spaceBetween: 50
            },
          },
          scrollbar: {
            el: ".swiper-scrollbar",
            draggable: true,
          },
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          },
        });
      }

    })
  })
  window.addEventListener('load', () => {
    const topMvSlider = document.querySelector('.m__top__mv')
    if (topMvSlider) {
      const keyName = 'visited'
      if (sessionStorage.getItem(keyName)) {
        new Swiper(topMvSlider, {
          loop: true,
          effect: 'fade',
          autoplay: {
            delay: 4000,
            disableOnInteraction: false,
          },
          speed: 2000,
        })
      } else {
        setTimeout(function () {
          new Swiper(topMvSlider, {
            loop: true,
            effect: 'fade',
            autoplay: {
              delay: 4000,
              disableOnInteraction: false,
            },
            speed: 2000,
          })
        }, 6500)
      }
    }
  })
}
