export const loading = () => {
  const keyName = 'visited'
  const keyValue = true
  const notScroll = (event) => {
    event.preventDefault()
  }
  document.addEventListener('DOMContentLoaded', () => {
    const loadingBlock = document.getElementById('js__loading')
    if (loadingBlock != null) {
      if (!sessionStorage.getItem(keyName)) {
        // ここに初回アクセス時の処理
        loadingBlock.classList.add('loading')
      } else {
        loadingBlock.classList.add('session')
      }
    }
  })
  window.addEventListener('load', () => {
    const loadingBlock = document.getElementById('js__loading')
    if (loadingBlock != null) {
      if (!sessionStorage.getItem(keyName)) {
        // sessionStorageにキーと値を追加
        sessionStorage.setItem(keyName, keyValue)
        // ここに初回アクセス時の処理
        loadingBlock.classList.add('loaded')
        // スクロールを禁止させ、ページ最上部へスクロールさせる
        document.addEventListener('touchmove', notScroll, { passive: false })
        document.addEventListener('mousewheel', notScroll, { passive: false })
        setTimeout(() => {
          window.scroll({top: 0})
        }, 100)
        // ヘッダーを読み込み完了時に一瞬表示させないための処理
        document.querySelector('.m__header').style.top = -210 + 'px'
        // 読み込み完了の演出が終わった後、スクロール禁止を解除する
        setTimeout(() => {
          document.removeEventListener('touchmove', notScroll, { passive: false })
          document.removeEventListener('mousewheel', notScroll, { passive: false })
        }, 7000)
        // ヘッダーを読み込み完了後、数秒待ってヘッダーを戻す
        setTimeout(() => {
          document.querySelector('.m__header').style.top = 0
        }, 9000)
      }
    }
  })
}
