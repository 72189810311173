export const topScrollEvent = () => {
  const eventTrigger = () => {
    const scrollEventArr = document.querySelectorAll('.js__scroll-event')
    if (scrollEventArr != null) {
      scrollEventArr.forEach((scrollEventElem) => {
        const scrollElemPos = scrollEventElem.getBoundingClientRect()
        const scrollEventPoint = scrollElemPos.top - (window.innerHeight / 1.5)
        if (scrollEventPoint < 0) {
          scrollEventElem.classList.add('js__event-start')
        } else {
          scrollEventElem.classList.remove('js__event-start')
        }
      })
    }
  }
  document.addEventListener('DOMContentLoaded', () => {
    eventTrigger()
  })
  window.addEventListener('scroll', () => {
    eventTrigger()
  })
}
