import { slideToggle } from '../libs/accordion'

export const spAccordion = () => {
  const accordionTriggers = document.querySelectorAll('.js__sp-accordion-trigger')

  accordionTriggers.forEach((accordionTrigger) => {
    accordionTrigger.addEventListener('click', () => {
      if (window.innerWidth < 737) {
        const accordionTarget = accordionTrigger.nextElementSibling

        accordionTrigger.classList.toggle('open')
        slideToggle(accordionTarget, 300)
      }
    })
  })
}
