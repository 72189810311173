export const fixedHeader = () => {
  window.addEventListener('scroll', () => {
    const fixedElem = document.querySelector('.js__header-fixed')
    const header = document.querySelector('.m__header__pc-section')
    const searchModalBtn = document.querySelector('.m__modal__float-search-btn')
    if (fixedElem != null) {
      const scrollCount = document.documentElement.scrollTop,
        headerHeight = header.clientHeight
      if (scrollCount > headerHeight) {
        fixedElem.classList.add('fixed')
        searchModalBtn.classList.add('rise')
      } else {
        fixedElem.classList.remove('fixed')
        searchModalBtn.classList.remove('rise')
      }
    }
  })
}
