import { slideToggle } from '../libs/accordion'

export const accordion = () => {
  const accordionTriggers = document.querySelectorAll('.js__accordion-trigger')

  accordionTriggers.forEach((accordionTrigger) => {
    accordionTrigger.addEventListener('click', () => {
      const accordionTarget = accordionTrigger.nextElementSibling

      accordionTrigger.classList.toggle('open')
      slideToggle(accordionTarget, 300)
    })
  })

  // 保育の関わり方等のSP側横開きコンテンツのアコーディオン
  const horizontalAccordionTriggers = document.querySelectorAll('.js__horizontal-accordion-trigger')
  horizontalAccordionTriggers.forEach((trigger) => {
    trigger.addEventListener('click', () => {
      if (trigger.classList.contains('open')) {
        trigger.classList.remove('open')
        trigger.parentElement.classList.remove('open-accordion')
      } else {
        trigger.classList.add('open')
        trigger.parentElement.classList.add('open-accordion')
      }
    })
  })
}
