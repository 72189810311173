import { createApp } from 'vue'
import FacilitiesMap from './facilities-map.vue'

export class FacilitiesMapControl {
  start() {
    createApp(
      FacilitiesMap
    ).mount('#js-facilities-map')
  }
}
