import { slideUp, slideDown, slideToggle } from '../libs/accordion'
export const header = () => {
  document.addEventListener('DOMContentLoaded', () => {
    // ヘッダーメニューの開閉
    const headerBtns = document.querySelectorAll('.m__header__sp-section li')
    const header = document.querySelector('.m__header')
    headerBtns.forEach((headerBtn) => {
      headerBtn.addEventListener('click', () => {
        const dataHeader = headerBtn.dataset.header
        if (headerBtn.classList.contains('open') == false) {
          headerBtns.forEach((headerBtn) => {
            if (headerBtn.dataset.header != null) {
              headerBtn.classList.remove('open')
              header.classList.remove('fixed')
            }
          })
          const headerSearch = document.getElementById('header-search')
          const headerMenu = document.getElementById('header-menu')
          if (dataHeader == 'header-search') {
            headerMenu.style.zIndex = 2
            headerSearch.style.zIndex = 1
            slideUp(headerMenu, 300)
            slideDown(headerSearch, 300)
          } else if(dataHeader == 'header-menu') {
            headerSearch.style.zIndex = 2
            headerMenu.style.zIndex = 1
            slideUp(headerSearch, 300)
            slideDown(headerMenu, 300)
          }
        } else {
          slideToggle(document.getElementById(dataHeader), 300)
        }
        headerBtn.classList.toggle('open')
        header.classList.toggle('fixed')
      })
    })
  })
}
