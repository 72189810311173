import { header } from './user/header'
import { fixedHeader } from './user/fixed-header'
import { pcHeaderMenu } from './user/pc-header-menu'
import { accordion } from './user/accordion.js'
import { spAccordion } from './user/sp-accordion.js'
import { modal } from './user/modal.js'
import { FacilitiesMapControl } from './user/facilities-map-control'
import { carousel } from './user/carousel.js'
import { floatBanner } from './user/float-banner.js'
import { smoothScroll } from './user/smooth-scroll.js'
import { loading } from './user/loading'
import { topScrollEvent } from './user/top-scroll-event'
import { chart } from './user/chart'
header()
fixedHeader()
pcHeaderMenu()
accordion()
spAccordion()
modal()
carousel()
floatBanner()
smoothScroll()
loading()
topScrollEvent()
chart()

document.addEventListener('DOMContentLoaded', () => {
  // お知らせ
  if (document.querySelector('#js-brand-announces')) {
    const select = document.querySelector('#js-brand-announces-archive-year')
    select.addEventListener('change', () => {
      const year = select.value
      if (year) {
        window.location.href = `/news/${year}/`
      } else {
        window.location.href = '/news/'
      }
    })
  }

  // スマートフォン用電話番号
  const isIphone = (navigator.userAgent.indexOf('iPhone') > -1) ? true : false
  const isAndroid = (navigator.userAgent.indexOf('Android') > -1) ? true : false
  if (isIphone || isAndroid) {
    document.querySelectorAll('.js--tel').forEach((a) => {
      const html = `<a href="tel:${a.dataset.tel}">` + a.innerHTML + '</a>'
      a.innerHTML = html
    })
  }

  // 地図から探す
  if (document.querySelector('#js-facilities-map')) {
    const facilitiesMapControl = new FacilitiesMapControl()
    facilitiesMapControl.start()
  }
})
